<template>

  <v-content class="application_background">
    <div  class="globalRadiusView">
    <v-slide-x-transition mode="out-in">
      <router-view />
    </v-slide-x-transition>
     <!-- <dashboard-core-footer /> -->
  </div>
   
  </v-content>

</template>

<script>
export default { 
  name: "DashboardCoreView",

  components: {
    DashboardCoreFooter: () => import("./Footer"),
  },
};
</script>
<style>
</style>